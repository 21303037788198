<template>
  <div class="d-flex">
    <b-row v-if="showGoToPage" class="w-100 d-none d-md-block">
      <b-col class="select-option" lg="auto">
        <slot name="select-option" />
      </b-col>
    </b-row>
    <b-pagination
      class="w-100 justify-content-end mb-0"
      pills
      hide-goto-end-buttons
      v-bind="$attrs"
      v-on="$listeners"
      :total-rows="totalRows"
      :per-page="perPage"
    >
      <b-icon icon="chevron-double-left" variant="dark" slot="first-text" />
      <b-icon icon="chevron-left" variant="dark" slot="prev-text" />
      <b-icon icon="chevron-right" variant="dark" slot="next-text" />
      <b-icon icon="chevron-double-right" variant="dark" slot="last-text" />
    </b-pagination>
  </div>
</template>

<script>
export default {
  name: "custom-pagination",
  props: {
    goPageText: { type: String, default: "Vá para página" },
    totalRows: {
      default: 0,
      type: Number,
    },
    perPage: {
      default: 0,
      type: Number,
    },
    showGoToPage: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      pageInput: "",
    };
  },
  methods: {
    goToPage() {
      if (this.pageInput !== "") {
        this.$emit("input", this.pageInput);
      }
    },
  },
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped>
.pagination-page {
  width: 77px;
}
.select-option {
  width: 50%;
}

.paginate-gopage {
  color: $bahama-blue;
}

::v-deep .page-link {
  color: $bahama-blue;
}
::v-deep .page-item.active .page-link {
  background-color: $bahama-blue;
  border-color: $bahama-blue;
}
</style>
